import React, {useRef} from 'react';
import Image from 'next/image';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation} from 'swiper';
import ButtonChevron from '../../assets/images/circle-arrow.png';
SwiperCore.use([Navigation])

const Previews = ({content}) => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    const sliderOptions = {
        slidesPerView: 1.5,
        centeredSlides: true,
        spaceBetween: 30,
        speed: 700,
        loop: true,
        loopedSlides: 15,
        navigation: {
            prevEl: '.slide-preview-prev',
            nextEl: '.slide-preview-next'
        },
        breakpoints: {
            768: {
                slidesPerView: 3,
                centeredSlides: false
            },
            1024: {
                slidesPerView: 4,
                centeredSlides: false
            }
        }
    }

    return (
        <section className="podcast__previews">
            <div className="podcast__previews-container">
                <h4>Episode Preview</h4>
                <div className="podcast__previews-list">
                    <button className="slider-button slide-preview-prev" ref={prevRef}>
                        <Image src={ButtonChevron} alt="slider-arrow"/>
                    </button>
                    {content && <Swiper {...sliderOptions}>
                        {content.map((podcast, key) => {
                            return (
                                <SwiperSlide key={`podcast-${key}`}>
                                    <div className='podcast__preview'>
                                        <span>{podcast?.acf?.info ? podcast?.acf?.info : ''}</span>
                                        <div className="podcast__preview-image">
                                            <img src={podcast._embedded['wp:featuredmedia'][0].source_url} alt="slide-1"/>
                                        </div>
                                        <div className="podcast__preview-content">
                                            <h5 dangerouslySetInnerHTML={{__html: podcast?.title?.rendered ? podcast?.title?.rendered : ''}}/>
                                            <a href={podcast?.acf?.external_link ? podcast?.acf?.external_link : '#.'}
                                               className="btn-round" target="_blank">
                                                Listen Now
                                            </a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>}
                    <button className="slider-button slide-preview-next" ref={nextRef}>
                        <Image src={ButtonChevron} alt="slider-arrow"/>
                    </button>
                </div>
            </div>
        </section>
    )
}

export default Previews;