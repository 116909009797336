import React from 'react';
import Head from 'next/head';
import Hero from '../components/podcast/Hero';
import Listen from '../components/podcast/Listen';
import Hosts from '../components/podcast/Hosts';
import Previews from '../components/podcast/Previews';
import PodcastFooter from '../components/podcast/PodcastFooter';
import services from '../services'

const Podcast = ({ acf, podcasts }) => {
    return (
        <>
            <Head>
                <title>Little Honey Money</title>
                <meta property="og:title" content="Little Honey Money" />
                <meta property="og:url" content={`${process.env.NEXT_PUBLIC_APP_URL}/podcast`} />
                <meta property="og:image" content="/images/get-1.jpg" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Journal" />
                <meta property="og:locale" content="en_US" />
            </Head>
            <Hero/>
            <Listen content={acf?.two_images_with_center_text} info={acf?.available_info}/>
            <Hosts content={acf?.text_with_image}/>
            <Previews content={podcasts}/>
            <PodcastFooter/>
        </>
    )
}

export const getStaticProps = async () => {
    const defaultAcf = await services.api.podcast.getAcf();
    const podcasts = await services.api.podcast.getPodcasts()
    const acf = defaultAcf.acf;

    return {
        props: {
            acf,
            podcasts
        },
        revalidate: 60 * 2
    }
}

export default Podcast;