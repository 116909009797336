import React from 'react';
import Image from 'next/image';
import Moon from '../../assets/images/podcast/moon.svg';
import HeaderImage from '../../assets/images/podcast/podcast-header-img.svg';
import Stars from '../../assets/images/podcast/stars.svg';

const Hero = () => {
    return (
        <section className="podcast__hero">
            <div className="podcast__hero-container">
                <div className="podcast__hero-moon">
                    <Image src={Moon} alt="moon"/>
                </div>
                <div className="podcast__hero-header">
                    <Image src={HeaderImage} alt="header-img"/>
                </div>
                <div className="podcast__hero-stars">
                    <Image src={Stars} alt="Stars"/>
                </div>
            </div>
        </section>
    )
}

export default Hero;