import React from 'react';

const Hosts = ({content}) => {
    return (
        <section className="podcast__hosts">
            <div className="podcast__hosts-container">
                <div className="podcast__hosts-left">
                    {content?.title && <h4 dangerouslySetInnerHTML={{__html: content?.title}}/>}
                    {content?.info && <div dangerouslySetInnerHTML={{__html: content?.info}}/>}
                </div>
                <div className="podcast__hosts-right">
                    {content?.image && <img src={content?.image} alt="podcast-hosts"/>}
                </div>
            </div>
        </section>
    )
}

export default Hosts;