import React from 'react';
import Image from 'next/image';
import Link from '../UI/Link';
import Logo from '../../assets/images/podcast/lhm.svg';

const PodcastFooter = () => {
    return (
        <section className="podcast__footer">
            <div className="podcast__footer-container">
                <h2>The wisdom you need, when you need it.</h2>
                <Link href="/">
                    <Image src={Logo} alt="logo"/>
                </Link>
            </div>
        </section>
    )
}

export default PodcastFooter;