import React from 'react';

const Listen = ({content, info}) => {
    return (
        <section className="podcast__listen">
            <div className="podcast__listen-container">
                <div className="podcast__listen-left">
                    {content?.image_one && <img src={content?.image_one} alt="image-1"/>}
                </div>
                <div className="podcast__listen-center">
                    {content?.section_title && <h3 dangerouslySetInnerHTML={{__html: content?.section_title}}/>}
                    {content?.info && <div dangerouslySetInnerHTML={{__html: content?.info}}/>}
                    {content?.cta_label && <a href={content?.cta_link ? content?.cta_link : "#."} className="btn-solid">{content?.cta_label}</a>}
                </div>
                <div className="podcast__listen-right">
                    {content?.image_two && <img src={content?.image_two} alt="image-1"/>}
                </div>
            </div>
            {info && <div className="podcast__listen-available" dangerouslySetInnerHTML={{__html: info}}/>}
        </section>
    )
}

export default Listen;